<template>
  <div class="page-content">
    <page-breadcrumb v-if="item" title="Path detail" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="item">
        <form-generator :model="item" :schema="item_form_schema" />
      </b-card>
    </section>

    <section class="mb-2" v-if="item">
      <h2 class="mb-2">Lessons</h2>
      <data-table-ssr id="lesson_list" ref="lesson_list" :limit-base="18"
        :columns="lesson_fields" :get-list-fn="getLessons" :item_default_data="{ name: { type: 'translatable-text', value: null } }"
        :create_schema="create_lesson_schema" :create-row-fn="createLesson"
        :to-edit-fn="toLessonDetail"
        :delete-row-fn="deleteLesson"
        :more_actions="[{ text: 'Create lesson above', icon: 'PlusCircleIcon', handler: createLessonAbove, }]"
      />
    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import { safeGet, buildSelectOptions } from '@core/utils/index';
import service from '../service';
import lessonService from '../../lesson/service';
const game_type_options = [];
const item_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Title', field: 'title', input_type: 'ui-component' },
      { label: 'Game type', field: 'game_type', input_type: 'select', options: game_type_options, validate: { required: true }, disabled: true },
    ]
  },
];

const lesson_fields = [
  { label: 'Display order', field: 'display_order', input_type: 'number' },
  { label: 'Game type', field: 'type', input_type: 'select', options: game_type_options },
  { label: 'Title', field: 'name', display_type: 'translatable-text'}
];

const create_lesson_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Name', field: 'name',  input_type: 'ui-component'},
    ]
  }
];

export default {
  data(){
    return{
      item_form_schema, lesson_fields, create_lesson_schema,
      item: null,
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Gameplays',
          to: { name: 'learning_path-list' },
        },
        {
          text: `Type: ${this.item ? this.item.game_type : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    this.getItem();
    this.getTopics();
  },
  methods:{
    // item
    async getItem(){
      let response_data = await service.get({ id: this.itemId });
      if (response_data) {
        this.item = response_data.document;

        let levels = safeGet(() => buildSelectOptions(response_data.meta['GAME_TYPE'])) || [];
        game_type_options.length = 0;
        game_type_options.push(...levels);
      }
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item),
      });
      this.getItem();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "learning_gameplay.list" });
    },
    // lesson
    async createLesson(data) {
      let new_item = await lessonService.create({ ...data, type: this.item.game_type, gameplay_id: this.itemId });
      return new_item;
    },
    async createLessonAbove({ item }) {
      this.$refs.lesson_list.openCreatePopup({
        display_order: item.display_order - 0.5,
        name: { type: 'translatable-text', value: null },
      });
    },
    async getLessons({ limit, page, query }) {
      let data = await lessonService.getList({
        query: JSON.stringify({ ...query, gameplay_id: this.itemId}),
        limit, page,
      });
      let list = [], total = 0;
      if (data) {
        list = data.list;
        total = data.total;
      }
      return { list, total };
    },
    async deleteLesson({ _id }) {
      await lessonService.delete({ id: _id });
    },
    toLessonDetail({ _id }) {
      const routeData = this.$router.resolve({name: 'learning_lesson-detail', params: { id: _id }});
      window.open(routeData.href, '_blank');
      // this.$router.push({ name: 'learning_lesson-detail', params: { id: _id } });
    },
  },
}
</script>
